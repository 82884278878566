import { template as template_cf3ffb0777bf4763b7d535be71d7556d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import { i18n } from "discourse-i18n";
const BackToForum = template_cf3ffb0777bf4763b7d535be71d7556d(`
  <a href={{getURL "/"}} class="sidebar-sections__back-to-forum">
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackToForum;

import { template as template_d6cbd0f0c2a14408988f4eaeca50c087 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d6cbd0f0c2a14408988f4eaeca50c087(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_540adc5ab2614c86a835bebe73023cf5 } from "@ember/template-compiler";
const FKLabel = template_540adc5ab2614c86a835bebe73023cf5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
